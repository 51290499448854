:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}

.hero {
  display: flex;
  justify-content: space-between;
  background-color: grey;
}
.hero .hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}
.hero .left-h {
  padding: 2rem;
  /* 32px */
  padding-top: 1.5rem;
  /* 24px */
  flex: 3;
  /* 3/5 */
  display: flex;
  gap: 2rem;
  /* 32px */
  flex-direction: column;
}
.hero .left-h .the-best-ad {
  margin-top: 4rem;
  /* 64px */
  background-color: #363d42;
  border-radius: 4rem;
  /* 64px */
  width: fit-content;
  /* chieu dai vua voi noi dung*/
  padding: 20px 13px;
  text-transform: uppercase;
  /* viet hoa */
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  /* canh giua theo chieu doc */
  justify-content: flex-start;
}
.hero .left-h .the-best-ad div {
  position: absolute;
  background-color: var(--orange);
  width: 4.5rem;
  /* 72px */
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}
.hero .left-h .the-best-ad span {
  z-index: 2;
}
.hero .hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  /* 72px */
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero .hero-text div:nth-of-type(3) {
  font-size: 1rem;
  /* 40px */
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.hero .figures {
  display: flex;
  gap: 2rem;
}
.hero .figures div {
  display: flex;
  flex-direction: column;
}
.hero .figures div span:nth-of-type(1) {
  color: white;
  font-size: 1.6rem;
}
.hero .figures div span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}
.hero .hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.hero .hero-buttons :nth-child(1) {
  color: white;
  background-color: var(--orange);
  width: 8rem;
}
.hero .hero-buttons :nth-child(1):hover {
  color: #d86635;
  background-color: rgb(236, 190, 106);
}
.hero .hero-buttons :nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--orange);
}
.hero .hero-buttons :hover {
  color: white;
  background-color: var(--orange);
}
.hero .right-h {
  flex: 1;
  /* 1/5 */
  position: relative;
  background-color: var(--orange);
}
.hero .right-h .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
}
.hero .right-h .heart-rate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: flex-start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}
.hero .right-h .heart-rate :nth-child(2) {
  color: var(--gray);
}
.hero .right-h .heart-rate :nth-child(3) {
  color: white;
  font-size: 1.5rem;
}
.hero .right-h .heart-rate img {
  width: 2rem;
}
.hero .right-h .hero-image {
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
  /* 368px */
  z-index: 1;
}
.hero .right-h .hero-image-back {
  position: absolute;
  z-index: 0;
  top: 4rem;
  right: 20rem;
  width: 15rem;
}
.hero .right-h .calories {
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: 13rem;
  position: absolute;
  top: 32rem;
  /* 512px */
  right: 28rem;
  /* 448px */
}
.hero .right-h .calories img {
  width: 3rem;
}
.hero .right-h .calories div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hero .right-h .calories div :nth-child(1) {
  color: var(--gray);
}
.hero .right-h .calories div :nth-child(2) {
  color: white;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .hero .hero-text {
    font-size: xx-large;
    align-items: center;
    /* canh giua theo chieu doc */
    justify-content: center;
    /* canh giua theo chieu ngang */
  }
  .hero .hero-text > div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    text-align: center;
  }
  .hero .hero-blur {
    width: 14rem;
  }
  .hero .the-best-ad {
    margin-top: 0;
    font-size: small;
    align-self: center;
    /* canh giua theo chieu ngang */
    transform: scale(0.8);
    /* thu nho */
  }
  .hero .hero-buttons {
    justify-content: center;
  }
  .hero .hero-buttons .btn {
    font-size: small;
  }
  .hero .figures > div > span:nth-of-type(1) {
    font-size: larger;
  }
  .hero .figures > div > span:nth-of-type(2) {
    font-size: small;
  }
  .hero .right-h {
    position: relative;
    background: none;
  }
  .hero .right-h .btn {
    z-index: 1;
  }
  .hero .right-h .heart-rate {
    left: 1rem;
    top: 2rem;
    z-index: 1;
  }
  .hero .right-h .hero-image {
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
    z-index: 2;
  }
  .hero .right-h .hero-image-back {
    width: 15rem;
    left: 2rem;
    top: 0;
    z-index: 0;
  }
  .hero .right-h .calories {
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .hero .right-h .calories img {
    width: 2rem;
  }
  .hero .right-h .calories > div > :nth-child(2) {
    color: white;
    font-size: 1rem;
  }
}