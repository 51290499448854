:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}

.Programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.Programs .programs-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
}
.Programs .programs-categories {
  display: flex;
  gap: 1rem;
}
.Programs .programs-categories .categrory {
  display: flex;
  flex-direction: column;
  background-color: gray;
  padding: 2rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}
.Programs .programs-categories .categrory :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
  /* fill color of svg */
}
.Programs .programs-categories .categrory :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.Programs .programs-categories .categrory :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}
.Programs .programs-categories .categrory .join-now {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.Programs .programs-categories .categrory .join-now span {
  display: flex;
  width: 5rem;
  align-items: center;
}
.Programs .programs-categories .categrory .join-now img {
  width: 1rem;
}
.Programs .programs-categories .categrory:hover {
  background: var(--planCard);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Programs .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .Programs .programs-categories {
    flex-direction: column;
  }
}