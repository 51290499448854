/*make variables*/
:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}

.header {
  display: flex;
  justify-content: space-between;
}
.header .logo {
  width: 10rem;
  /* 160px */
  height: 3rem;
  /* 48px */
}
.header .header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.header .header-menu li:hover {
  cursor: pointer;
  color: var(--orange);
}

@media screen and (max-width: 768px) {
  .header > :nth-child(2) {
    position: fixed;
    z-index: 100;
    right: 2rem;
  }
  .header .header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}