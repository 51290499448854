/*make variables*/
:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}

.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.plans-container .plans-blur1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}
.plans-container .plans-blur2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}
.plans-container .programs-header {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  color: white;
  font-size: 2.4rem;
  font-weight: bold;
  font-style: italic;
}
.plans-container .plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.plans-container .plans .plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
}
.plans-container .plans .plan:nth-child(2) {
  background: var(--planCard);
  scale: 1.1;
}
.plans-container .plans .plan:nth-child(2) > svg {
  fill: white;
}
.plans-container .plans .plan:nth-child(2) > button {
  color: var(--orange);
}
.plans-container .plans .plan button:hover {
  background: var(--orange);
  color: white;
  transform: scale(1.1);
}
.plans-container .plans .plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.plans-container .plans .plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.plans-container .plans .plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.plans-container .plans .plan > :nth-child(5) {
  font-size: 0.8rem;
  font-weight: bold;
}
.plans-container .plans .plan .features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.plans-container .plans .plan .features .feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.plans-container .plans .plan .features .feature > img {
  width: 1rem;
}

@media screen and (max-width: 768px) {
  .plans-container .programs-header {
    display: none;
  }
  .plans-container .plans {
    flex-direction: column;
  }
  .plans-container .plans > :nth-child(2) {
    transform: none;
  }
}