/*make variables*/
:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}

.Join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.Join .left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.Join .left-j hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}
.Join .left-j > div {
  display: flex;
  gap: 1rem;
}
.Join .right-j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.Join .right-j .email-container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}
.Join .right-j .email-container input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
.Join .right-j .email-container ::placeholder {
  color: var(--lightgray);
}
.Join .right-j .email-container .btn-j {
  background-color: var(--orange);
  color: white;
}

@media screen and (max-width: 768px) {
  .Join {
    flex-direction: column;
    gap: 1rem;
  }
  .Join .left-j {
    font-size: x-large;
    flex-direction: column;
  }
  .Join .left-j hr {
    width: 8rem;
  }
  .Join .right-j {
    padding: 2rem;
  }
  .Join .right-j .email-container {
    flex-direction: column;
    gap: 1rem;
  }
}